<template>
    <div class="page1">
        <EButton type="primary" @click="showDialog">增加</EButton>
        <ETable :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="false"
        >
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180px"
            >
                <template slot-scope="scope">
                    <div>
                        <EButton type="text" @click="editDialog(scope.row)">
                            修改
                        </EButton>
                        <EButton type="text" @click="remove(scope.row.businessScopeId)">
                            删除
                        </EButton>
                        <EButton type="text" @click="change(scope.row.businessScopeId,scope.row.status)">
                            {{scope.row.status==1?'禁用':'启用'}}
                        </EButton>
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <EDialog :dialogVisible="dialogVisible" :title="title" @handleClose="dialogVisible=false"
                 width="30%"
                 @handleClick="handleClick"
        :disabled="saveDisabled">
            <EForm
                    :formColumns="dialogFormColumns"
                    :rowSize="1"
                    :optionsBtn="false"
                    :actionBtn="true"
                    :formData="dialogForm"
                    ref="form"
                    :searchFlag="false"
                    :labelPosition="labelPosition"
                    :labelWidth="labelWidth"
                    :formRules="formRules"
            >
            </EForm>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import {identity_type, status} from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";
  export default {
    name: 'Login',
    mixins:[otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '经营范围',
            prop: 'businessScopeName'
          },
          {
            label: '新建时间',
            prop: 'createTime'
          },
          {
            label: '新建人',
            prop: 'createUserCn'
          },
          {
            label: '最近一次修改时间',
            prop: 'updateTime',
          },
          {
            label: '修改人',
            prop: 'updateUserCn'
          },
          {
            label: '状态',
            prop: 'statusCn'
          },
          {
            label: '备注',
            prop: 'remark'
          },
        ],
        tableData: [
        ],

        count: null,
        dialogFormColumns: [
          {
            title: '经营范围',
            type: 'text',
            property: 'businessScopeName',
            placeHolder: '最多可录入20字',
            show: true,
          },
          {
            type:'actionBtn',
            show:false
          }
        ],
        exportData:{},
        dialogVisible:false,
        labelPosition:'right',
        labelWidth:'100px',
        formRules:{
          businessScopeName: vxRule(true, '',"blur", "经营范围不能为空")
        },
        dialogForm:{
          businessScopeId:'',
          businessScopeName:''
        },
        options:[],
        title:'新增经营范围'
      }
    },
    watch: {},
    created() {
      this.getData()
    },
    components: {ETable, EDialog, EButton, EForm},
    beforeMount() {

    },
    methods: {
      async changeStatus(id,status){
        let res=await Http.cmbusinessscopeconfigUpdStatus({id,status})
        if(res.code==200){
          this.$message.success(res.msg)
          this.getData()
        }
      },
      change(id, status) {
        let msg = ''
        if (status == 1) {
          msg = '禁用'
        } else {
          msg = '启用'
        }
        this.$messageBox.confirm('确定' + msg + '？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(res => {
          this.changeStatus(id, status==1?0:1)
        }).catch(res => {

        });
      },
      editDialog(data){
        this.dialogVisible=true
        this.dialogForm.businessScopeId=data.businessScopeId
        this.dialogForm.businessScopeName=data.businessScopeName
      },
      remove(data){
        this.$messageBox.confirm('确定删除该经营范围吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(res => {
          this.roleRemove(data.businessScopeId)
        }).catch(res => {

        });
      },
      async cmbusinessscopeconfigRemove(ids){
        let res=await Http.cmbusinessscopeconfigRemove({ids})
        if(res.code==200){
          this.$message.success(res.msg)
          this.getData()
        }
      },
      cancelDialog(){
        this.dialogVisible=false
        this.dialogForm={
          businessScopeId:'',
          businessScopeName:''
        }
      },
      showDialog(){
        this.dialogVisible=true
      },
      handleClick(){
        this.$refs['form'].$refs['form'].validate((valid, object) => {
          console.log(object)
          if (valid && !this.error) {
            this.cmbusinessscopeconfigSubmit()
          } else {
            return false;
          }
        });
      },
      async cmbusinessscopeconfigSubmit(){
        this.setDisabled(true)
        let res=await Http.cmbusinessscopeconfigSubmit(this.dialogForm)
        if(res.code==200){
          this.$message.success(res.msg)
          this.setDisabled(false)
          this.cancelDialog()
          this.getData()
        }
      },
      async getData(){
        let res = await Http.getBusinesssCopeList()
        if(res.code == 200){
          this.tableData = res.data
        }
      }
    }
  }
</script>

<style lang="scss">

</style>
